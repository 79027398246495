import Cookies from 'universal-cookie';

const localf                  = false;   
export const site_name        = 'digiVC';
const        domain           = 'digivc.me';
export const site_type        = 'restaurant';
export const dashColor = {
  ptColor               : '#8d6b6f',
  pttColor              : '#ffffff',

  bgPaperLight          : 'white', 
  darkBool              : 'light',
}


export const email_domain_add = domain;
export const domain_name      = localf ? 'http://localhost:3002' : 'https://www.' + domain; 
export const server           = 'https://api-prod.' + domain
export const help_email       = 'support@' + email_domain_add;

var inProd =( (window.location.href).includes("localhost")  || window.location.href.includes("192.168") )  ? false : true;
export const local = inProd ? false : true;


//parrot
function ft (x,y) { return x }
export var p = { t : ft }
export const parrot_key = '23zdQyXPMFzxDvxNST3f8'; 

//crisp
export function getCrispWebsiteID() {
    return (store_type == "ecommerce") ? "97a7279c-346e-459f-b525-13a9a1516b8d" 
                                       : "ae584e9f-99e0-4878-83ab-1d9ed1a0a8cc";
}

//paddle
export const paddle_vendor_id = 115440; 

//insepectlet
export function getInspectletWID() {
    return (store_type == "ecommerce") ? 825117434 : 1359076317;
}

// this is for opening menu where
export const store_type =  "food"; 

//export const enablePhoneLogin = false;  //keep it disabled till sms are enabled
export const enablePhoneLogin = (store_type == "ecommerce") ? true : false;

export const enableGoogleLogin = false

export const cookies = new Cookies();
export const MAX_INT = 1000000000; //2^31-1
export const RZP_RN_SDK = false;


export function signUpContent (p) { 
  var signUpContent = [{ 
    title : 'Create Digital Visting Cards',
    content : 'Quickly create your and your teams cards in 5 min.',
  },{
    title : 'Custom Domain',
    content : 'Link it to your domain for more trust',
  },{
    title : 'Manage any time',
    content : 'Change content of the cards any time',
  },
  ];
  return signUpContent;
}

export function subscriptionPlans (p) { 
  var subscriptionPlans = [{ 
    plan_name : 'FREE TRIAL',
    plan_price : '$ 1',
    plan_duration : 'per month',
    feature1 : 'Single Location',
    feature2 : 'Digital Ordering',
    feature3 : 'Email Support'
  },{
    plan_name : 'BUSINESS',
    plan_price : '$ 10',
    plan_duration : 'per month',
    feature1 : 'Multiple Locations (upto 10)',
    feature2 : 'Includes Order Management',
    feature3 : 'Email Support'
  },{
    plan_name : 'PREMIUM',
    plan_price : '$ 100',
    plan_duration : 'per month',
    feature1 : 'Multiple Locations (upto 100)',
    feature2 : 'Includes Order Management',
    feature3 : 'Premium Support 24/7'
  },
  ];
  return subscriptionPlans;
}


export function blog_data (p) { 
 var blog_data = []
 return blog_data;
}

export const test_mode = (local == false) ? 0 : 1;
//export const test_mode = 0 ;
export const available_payment_modes = {}
export const apmd = {}
export const invoice_specs_base = []


export function getMOS() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent))  // Windows Phone must come first because its UA also contains "Android"
      return "Windows Phone";
  if (/android/i.test(userAgent))
      return "Android";
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) // iOS detection from: http://stackoverflow.com/a/9039885/177710
      return "iOS";
  return "unknown";
}






