import React from 'react';
import { Component,Fragment } from 'react'
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';

//create a new vCard
import queryString from 'query-string';



function downloadURI(uri, name) {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  //delete link;
}



class Cards extends Component {

  constructor(props){
      super(props)
      this.state = {
       
      }
  }

  componentDidMount() {


    var q = queryString.parse(window.location.search); 
    console.log(q)
    var id = q.id
    let url = 'https://api-prod.digivc.me/get/profile?id=' + id ;

    fetch(url)
    .then(response => response.json())
    .then(jsondata => { 
      if(jsondata.status == "success") {
        var profile = jsondata.data
        this.setState({ profile : profile, id : id })
      }
    })

  }

  handleClick = (vCard) => {

    var s = "BEGIN:VCARD\r\nVERSION:3.0\r\nFN;CHARSET=UTF-8:Eric J Nesser\r\nN;CHARSET=UTF-8:Nesser;Eric;J;;\r\nBDAY:19850101\r\nPHOTO;TYPE=JPEG:https://avatars2.githubusercontent.com/u/5659221?v=3&s=460\r\nTEL;TYPE=WORK,VOICE:312-555-1212\r\nTITLE;CHARSET=UTF-8:Software Developer\r\nORG;CHARSET=UTF-8:ACME Corporation\r\nURL;CHARSET=UTF-8:https://github.com/enesser\r\nNOTE;CHARSET=UTF-8:Notes on Eric\r\nREV:2022-09-20T08:23:20.536Z\r\nEND:VCARD\r\n"

    var d = JSON.parse(s)

    downloadURI("data:text/vcard," + d,'test1.vcf');

  }

  handleSaveContact = () => {
    window.open('https://api-prod.digivc.me/get/vcard?id=' + this.state.id,"_self")
  }

 
  render() {
  const { classes } = this.props;
  
  console.log('here')

  var profile = this.state.profile ? this.state.profile : {}
  
  
  return (
    <Container style={{ width : '100vw', align : 'center'}}>
    <Grid container direction='row' alignItems='center' justifyContent='center'>
    {profile.firstName &&
    <Grid container alignItems='center' justifyContent='center' direction='column'>

    <Paper style={{ height : '200px', minWidth : '300px', maxWidth : '500px', padding : '8px', margin : '20px 0px 20px'}}>
    <Grid container>
    <Grid item xs={4}>
    <Grid container direction='column' justifyContent='center' alignItems='center'>
    <img style={{ marginTop : '30px', marginLeft : '8px'}} align='center' width = '140px' height = '140px' src={profile.thumbnail}/>
    </Grid>
    </Grid>

    <Grid container xs={8} justifyContent='space-between' style={{ marginTop : '20px'}}>
    
    <Grid container direction='column' alignItems='flex-end' >
    <Typography variant='h5' children={profile.firstName + ' ' + profile.lastName}/>
    <Typography variant='subtitle1' children={profile.title}/>
    <Typography variant='subtitle1' children={profile.organization }/>
    </Grid>

    <Grid container direction='column' alignItems='flex-end' justifyContent='flex-end'>
    <Typography variant='caption' onClick={e => window.open('mailto:'+ profile.workEmail, '_self') } children={profile.workEmail}/>
    <Typography variant='caption' onClick={e => window.open('tel:'+ profile.cellPhone, '_self') } children={profile.cellPhone}/>
    </Grid>


    </Grid>
    </Grid>
    </Paper>
  
    <Button variant='contained' style={{ backgroundColor : '#397eb4', color : 'white' }}  onClick={e => this.handleSaveContact()}>
    <Typography variant='subtitle1' children='Save Contact'/>
    </Button>

    </Grid>}
    </Grid>
    </Container>
  );
  }
}

export default Cards


// Case left handling network errors
// Form dialog nomenclature
// Language based string accessibility
// To add abilities to modify restaurants

