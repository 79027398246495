import React from 'react';
import { Component,Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import  makeStyles  from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import { server,p } from '../../environ';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';


import Tooltip from '@material-ui/core/Tooltip';



const queryString = require('query-string');


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
   err_msg: {
    marginTop: 10,
  },
   largeIcon: {
    width: 60,
    height: 60,
    marginBottom : 20
  },
}));

class ChangePasswordW extends Component {

  constructor(props){
      super(props)
      this.state = {

        error : null,
        success : false,
        helpText : null,
        refresh : false
        
      }
  }

   componentDidMount(){

      document.getElementById("root").addEventListener('parrot_data',e => this.setState({refresh:!this.state.refresh}),false);

    }



  handleError = (msg) => {
    this.setState({error : msg, success : null, helpText : null});
  }

  handleSuccess = (x) => {
    this.setState({success : x, error : null, helpText : null});
  }

  handleChange = (e) => {
    this.setState({error: null, helpText : null, success : null });
  }


  showHelp = (e,p) => {
    this.setState({ helpText : p.t("Use 8 or more characters with a mix of letters, numbers",'/helptext'),error : null, success : null } );
  }


  handleSubmit = (e,p) => {

      e.preventDefault();
      var new_password        = document.getElementById("new-password").value;   
      var retype_new_password = document.getElementById("retype-new-password").value;   

      if ( new_password != retype_new_password ) return this.handleError(p.t("Password is not matching",'/password/notmatching'))


      var parsed                = queryString.parse(this.props.location.search);
      var reset_password_token  = (parsed !=undefined && parsed.reset_password !=undefined ) ? parsed.reset_password : '0';
      var access_token          = (parsed !=undefined && parsed.access_token != undefined ) ? parsed.access_token : '0'; 
      var lc  = (parsed.lc !=undefined ) ? parsed.lc : 'en'; 

      var url = server + '/change_password';
      let data = { reset_password_token: reset_password_token, access_token: access_token, new_password: new_password };
      data.lc = lc;

      var options = { method: 'POST',
                      headers: { 'Content-Type': 'application/json;charset=utf-8'
                      },
                      body: JSON.stringify(data)
                    };

      fetch(url,options)
      .then(res=>res.json())
      .then(res => {  
          if(res.status == "success")
          {
            this.handleSuccess(true);
            setTimeout( () => { window.location.href = window.location.origin  + '/signin?' + 'email=' + res.data.email  }, 6000 );
          }
          else
          {
            this.handleError(res.message)
          }
      });
  }


  render () {
      const { classes } = this.props;

      var parsed = queryString.parse(this.props.location.search);
      var lc  = (parsed.lc !=undefined ) ? parsed.lc : 'en';

  return (
    <Container component="main" maxWidth="xs">
    <Box mt={10}>
      <CssBaseline />
      { !this.state.success ? 
      <div className={classes.paper}>
      
        <Typography component="h1" variant="h5" align="center">
          {p.t('Change Password','/title')}
        </Typography>

        <form className={classes.form} noValidate onSubmit={e => this.handleSubmit(e,p)} >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="new-password"
            label={p.t("New Password",'/password/new')}
            type="password"
            name="new-password"
            autoFocus
            onChange={e => this.handleChange(e)}
            InputProps={{
            endAdornment : 
              <InputAdornment position="end">
                <Tooltip title={p.t("Use 8 or more characters with a mix of letters, numbers",'/helptext/tooltip')} enterDelay={500}>
                <IconButton
                  aria-label="Help text" onClick={e => this.showHelp(e,p)}
                >
                <HelpOutlineIcon />
                </IconButton>
                </Tooltip>
              </InputAdornment>
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="retype-new-password"
            label={p.t("Retype New Password",'/password/retype')}
            type="password"
            name="retype-new-password"
            autoComplete="current-password"
            onChange={e => this.handleChange(e)}
          />
          <div style={{ marginTop : 8}}>
          <div align = "center" className={classes.err_msg}>
            <Typography color="error"> {this.state.error}</Typography> 
          </div>
          <div align = "center" className={classes.err_msg}>
            <Typography color="textPrimary"> {this.state.helpText}</Typography> 
          </div>
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            style={{ marginTop : 24}}
          >
            {p.t('Submit','/submitbutton')}
          </Button>

      
        </form>
      </div> :
      <div align="center" className={classes.paper}>
        <CheckCircleOutlineIcon style={{fill: "green"}} className={classes.largeIcon}  />

      
        <Typography variant="body1">
          {p.t('The password has been changed. Redirecting you to the dashboard in a few seconds.','/success/msg')}
        </Typography>
        
      </div>
      }
    </Box>
    </Container>
  ); }
}

const ChangePassword = withStyles(useStyles)(ChangePasswordW);
export default ChangePassword


