import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { server,cookies,local,parrot_key } from './components/environ';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import {blue,pink,grey} from '@material-ui/core/colors/';

import './App.css';

import Terms            from   './components/vendor/signin/terms';
import Privacy          from   './components/vendor/signin/privacy';
import UserVerified     from   './components/vendor/signin/UserVerified';
import ErrorPage        from   './components/vendor/signin/ErrorPage';
import ChangePassword   from   './components/vendor/signin/ChangePassword';

const  SignInForm         = lazy(() =>  import('./components/vendor/signin/SignInForm'));
const   VendorView        = lazy(() => import('./components/vendor/dashboard/VendorView'));

import Cards   from  './components/cards';

function App() {
  
  return (
    <Router>
    <Suspense fallback={<div></div>}>
    <Switch> 

      <Route  path="/dashboard"       component={VendorView} />

      <Route  path="/terms"           component={Terms} />
      <Route  path="/privacy"         component={Privacy} />

      <Route  path="/signin"          component={SignInForm} />
      <Route  path="/changepassword"  component={ChangePassword} />
      <Route  path="/userverified"    component={UserVerified} />
      <Route  path="/errorpage"       component={ErrorPage} />

      <Route  path="/vc"           component={Cards} />

    </Switch>
    </Suspense>
    </Router>
  );
}

export default App


//ReactDOM.render(<App />, document.querySelector('#app'));


